import Vue from "vue";
import router from "@/router";

// Import the plugin here
import { Auth0Plugin } from "./index";

// Import the Auth0 configuration
const { domain, clientId, audience } = window.__env.auth0_config;

export default () => {
  // Install the authentication plugin here
  Vue.use(
    Auth0Plugin,
    {
      domain,
      clientId,
      audience,
      onRedirectCallback: appState => {
        router.push(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        );
      }
    }
  );
};
