<template>
  <div class="sidenav-header">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "sidenav-header"
};
</script>
