import Vue from "vue";
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

export default () => {

  if (window.__env.insights_config) {

    const appInsights = new ApplicationInsights({ 
      config: {
        connectionString: window.__env.insights_config.connectionString
      }
    });

    if (window.__env.insights_config.connectionString) {

      appInsights.loadAppInsights();
      appInsights.trackPageView();

    }
    
    Vue.use((app, options) => {    
      app.prototype.$appInsights = appInsights;
    });

  }

}