import { getInstance } from "./index";

export default (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {

      // JNagy: Role shouldn't matter here.  If the user can log in, they can use the site.

      // if ((await authService.getUserRole()) !== "Administrator") {
      //   authService.logout();
      //   return null;
      // }

      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};
