<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <!-- Brand -->
    <div class="app-brand py-3">
      <router-link to="/" class="d-block w-100">
        <img src="/images/logo.png" alt class="app-brand-img" />
        <img src="/images/logo-small.png" alt class="app-brand-img-collapsed mx-auto" />
      </router-link>
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto"
        @click="toggleSidenav()">
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <!-- / Brand -->
    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <sidenav-router-link icon="ion ion-md-analytics" to="/" :exact="true">Overview</sidenav-router-link>
      <sidenav-router-link v-if="!isReportUser" icon="ion ion-md-trending-up" to="/trends" :exact="true">
        Trends
      </sidenav-router-link>

      <sidenav-router-link icon="ion ion-md-cart" to="/sales" :exact="true">Sales</sidenav-router-link>
      <sidenav-menu v-if="!isReportUser" icon="ion ion-ios-cog" :active="isMenuActive('/machines') || isMenuActive('/machines/archived')
        " :open="isMenuOpen('/machines') || isMenuOpen('/machines/archived')">
        <template slot="link-text">Machines</template>
        <sidenav-router-link v-if="!isReportUser" to="/machines" :exact="false">
          Machines
        </sidenav-router-link>
        <sidenav-router-link v-if="!isReportUser" to="/machines/archived" :exact="false">
          Archived
        </sidenav-router-link>
      </sidenav-menu>
      <sidenav-router-link icon="ion ion-md-finger-print" to="/devices" :exact="false"
        v-if="this.hasDeviceClaims && (!isReportUser)">
        Devices
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-cube" to="/groups" :exact="false"
        v-if="this.hasGroupClaims && (!isReportUser)">
        Groups
      </sidenav-router-link>
      <sidenav-menu v-if="!isReportUser" icon="ion ion-ios-beaker" :active="isMenuActive('/products') ||
        isMenuActive('/planograms') ||
        isMenuActive('/priceLists')
        " :open="isMenuOpen('/products') ||
          isMenuOpen('/planograms') ||
          isMenuOpen('/priceLists')
          ">
        <template slot="link-text">Products</template>
        <sidenav-router-link v-if="!isReportUser" to="/products" :exact="false">
          Products
        </sidenav-router-link>
        <sidenav-router-link v-if="!isReportUser" to="/planograms" :exact="false">
          Planograms
        </sidenav-router-link>
        <sidenav-router-link v-if="!isReportUser" to="/priceLists" :exact="false">
          Price Lists
        </sidenav-router-link>
      </sidenav-menu>
      <sidenav-router-link v-if="!isReportUser" icon="ion ion-ios-flash" to="/attracts" :exact="false">
        Attracts
      </sidenav-router-link>
      <sidenav-router-link v-if="!isReportUser" icon="ion ion-md-stats" to="/status" :exact="true">
        Status
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-contacts" to="/users" :exact="true"
        v-if="this.hasUserEditClaims && (!isReportUser)">
        Users
      </sidenav-router-link>
      <sidenav-menu icon="ion ion-md-archive" :active="isMenuActive('/Reports') ||
        isMenuActive('/reports/transactions') ||
        isMenuActive('/reports/bulkupdates') ||
        isMenuActive('/reports/events') ||
        isMenuActive('/reports/cashupdates') ||
        isMenuActive('/reports/devicetransactions') ||
        isMenuActive('/reports/serviceforecast') ||
        isMenuActive('/reports/deviceinteractions') ||
        isMenuActive('/reports/weeklysales') ||
        isMenuActive('/reports/rfidtags')
        " :open="isMenuOpen('/Reports') ||
          isMenuOpen('/reports/transactions') ||
          isMenuOpen('/reports/bulkupdates') ||
          isMenuOpen('/reports/events') ||
          isMenuOpen('/reports/cashupdates') ||
          isMenuOpen('/reports/devicetransactions') ||
          isMenuActive('/reports/serviceforecast') ||
          isMenuActive('/reports/deviceinteractions') ||
          isMenuActive('/reports/weeklysales') ||
          isMenuActive('/reports/rfidtags')
          ">
        <template slot="link-text">Reports</template>
        <sidenav-router-link to="/reports/transactions" :exact="true" v-if="this.hasReportsClaims">
          Transactions
        </sidenav-router-link>
        <sidenav-router-link to="/reports/devicetransactions" :exact="true" v-if="this.hasReportsClaims">
          Device Transactions
        </sidenav-router-link>
        <sidenav-router-link to="/reports/serviceforecast" :exact="true" v-if="this.hasReportsClaims">
          Service Forecast
        </sidenav-router-link>
        <sidenav-router-link to="/reports/bulkupdates" :exact="true" v-if="this.hasReportsClaims">
          Bulk Updates
        </sidenav-router-link>
        <sidenav-router-link to="/reports/cashupdates" :exact="true" v-if="this.hasReportsClaims">
          Cash Updates
        </sidenav-router-link>
        <sidenav-router-link to="/reports/events" :exact="true" v-if="this.hasReportsClaims">
          Events
        </sidenav-router-link>
        <sidenav-router-link to="/reports/deviceinteractions" :exact="true" v-if="this.hasReportsClaims">
          Device Interactions
        </sidenav-router-link>
        <sidenav-router-link to="/reports/weeklysales" :exact="true" v-if="this.hasReportsClaims">
          Weekly Sales
        </sidenav-router-link>
        <sidenav-router-link to="/reports/rfidtags" :exact="true" v-if="this.hasReportsClaims">
          RFID Tags
        </sidenav-router-link>
      </sidenav-menu>
      <sidenav-block>
        <small>{{ apiVersionString }}</small><br />
        <!-- <small>{{ apiBuildDateString }}</small><br /> -->
        <small>{{ uiVersionString }}</small>
      </sidenav-block>
    </div>
  </sidenav>
</template>

<script>
import httpClient from "@/lib/httpClient";
import {
  Sidenav,
  SidenavBlock,
  SidenavDivider,
  SidenavHeader,
  SidenavLink,
  SidenavMenu,
  SidenavRouterLink
} from "@/vendor/libs/sidenav";
import { version } from "../../package.json";
import toDateTime from "../filters/toDateTime";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: "vertical"
    }
  },

  data() {
    return {
      hasUserEditClaims: false,
      hasGroupClaims: false,
      hasDeviceClaims: false,
      hasReportsClaims: false,
      versions: {},
      apiVersionString: "",
      apiBuildDateString: "",
      uiVersionString: "",
      isReportUser: false
    };
  },

  created() {

    this.loadData();

  },

  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    }
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },

    async loadData() {
      var claims = await this.$auth.getAuthTokenClaims();
      this.hasUserEditClaims = claims.find(x => x.startsWith("user:edit"));
      this.hasGroupClaims = claims.find(x => x.startsWith("group:"));
      this.hasDeviceClaims = claims.find(x => x.startsWith("device:"));
      this.hasReportsClaims = claims.find(x => x.startsWith("sales:view.all"))
        || claims.find(x => x.startsWith("sales:view.group"))
        || claims.find(x => x.startsWith("sales:view.assigned"));
      try {
        const { data } = (await httpClient.get("/system/info"));
        this.versions = data;
        this.apiVersionString = `API v${this.versions.apiVersion}`;
        this.apiBuildDateString = `(${toDateTime(new Date(this.versions.apiVersionBuildDate))})`;
        //this.commonVersionString =`Common v${this.versions.commonVersion}`;
        //this.commonBuildDateString = `${toDateTime(new Date(this.versions.commonVersionBuildDate))}`;
        this.uiVersionString = `UI v${version}`;

        const roles = await this.$auth.getUserRoles();

        this.isReportUser = !!(roles.filter(x => x === "reportuser").length);
      }
      catch (e) {

      }
    }
  }
};
</script>
