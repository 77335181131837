import Vue from "vue";
import Vuex from "vuex";
import httpClient from "@/lib/httpClient";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUserName: "",
    currentMachine: {},
    statesData: []
  },
  mutations: {
    updateCurrentUserName(state, name) {
      state.currentUserName = name;
    },
    updateCurrentMachine(state, machine) {
      state.currentMachine = machine;
    },
    updateStatesData(state, statesData) {
      state.statesData = statesData;
    }
  },
  actions: {
    async updateCurrentMachine({ commit }, machineId) {
      // Check if currentMachine is already set
      if (this.state.currentMachine.id === machineId) {
        return;
      }
      commit("updateCurrentMachine", {});

      const response = await httpClient.get(`/machines/${machineId}`);
      commit("updateCurrentMachine", response.data);
    },
    async forceUpdateCurrentMachine({ commit }, machineId) {
      const response = await httpClient.get(`/machines/${machineId}`);
      commit("updateCurrentMachine", response.data);
    },
    async fetchStatesData({ commit }) {
      if(this.state.statesData.length > 0) {
        return;
      }
      
      const response = await httpClient.get(`/states`);
      commit("updateStatesData", response.data);
    }
  },
});
