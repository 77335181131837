import Vue from "vue";

import toCurrency from "./toCurrency";
import toPercentage from "./toPercentage";
import toShortPercentage from "./toShortPercentage";
import toFormattedNumber from "./toFormattedNumber";
import toDateTime from "./toDateTime";
import toDate from "./toDate";
import toCupSize from "./toCupSize";
import toFileNameOnly from "./toFileNameOnly";
import toAbbreviatedId from "./toAbbreviatedId";
import toDateTimeFromString from "./toDateTimeFromString";
import toDateTimeFromLocalDateTime from './toDateTimeFromLocalDateTime';
import parseToDate from "./parseToDate";
import naIfNull from "./naIfNull";
import toNumberOfDaysFromNow from "./toNumberOfDaysFromNow";
import toDistanceFromNow from "./toDistanceFromNow";
import toBinLevel from "./toBinLevel";
import toBinState from "./toBinState";
import changeLevelPercentageToRange from "@/lib/calculateLevelPercentageAsRange";
import after2020 from "./after2020";

export default () => {
  Vue.filter("toCurrency", toCurrency);
  Vue.filter("toPercentage", toPercentage);
  Vue.filter("toShortPercentage", toShortPercentage);
  Vue.filter("toFormattedNumber", toFormattedNumber);
  Vue.filter("toDateTime", toDateTime);
  Vue.filter("toDate", toDate);
  Vue.filter("toCupSize", toCupSize);
  Vue.filter("toFileNameOnly", toFileNameOnly);
  Vue.filter("toAbbreviatedId", toAbbreviatedId);
  Vue.filter("toDateTimeFromString", toDateTimeFromString);
  Vue.filter("toDateTimeFromLocalDateTime", toDateTimeFromLocalDateTime);
  Vue.filter("parseToDate", parseToDate);
  Vue.filter("naIfNull", naIfNull);
  Vue.filter("toNumberOfDaysFromNow", toNumberOfDaysFromNow);
  Vue.filter("toDistanceFromNow", toDistanceFromNow);
  Vue.filter("toBinLevel", toBinLevel);
  Vue.filter("toBinState", toBinState);
  Vue.filter("changeLevelPercentageToRange", changeLevelPercentageToRange);
  Vue.filter("after2020", after2020);
};
