<template>
  <b-navbar-nav class="align-items-lg-center ml-auto">
    <b-nav-item-dropdown :no-caret="true">
      <template slot="button-content">
        <span
          class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
        >
          <b-avatar variant="light" :src="$auth.user.picture" />
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ $auth.user.name }}</span>
        </span>
      </template>
      <b-dd-item @click="logout">
        <i class="ion ion-ios-log-out text-danger"></i>
        &nbsp; Log Out
      </b-dd-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
export default {
  name: "profileDropdown",
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
};
</script>
