import format from "date-fns/format";

export default x => {
    if (x && x.date && x.timeOfDay) {
        var date = new Date(
            x.date.year,
            x.date.month - 1,
            x.date.day,
            x.timeOfDay.hour,
            x.timeOfDay.minute,
            x.timeOfDay.second,
        );
        return format(date, "yyyy-MM-dd HH:mm:ss");
    }
    return "N/A";
}  