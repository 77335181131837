<template>
  <b-navbar
    toggleable="lg"
    :variant="getLayoutNavbarBg()"
    class="layout-navbar align-items-lg-center container-p-x"
  >
    <!-- Brand -->
    <b-navbar-brand tag="h1" class="mb-2 mt-2">Skittles Vending</b-navbar-brand>

    <b-navbar-nav
      class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto"
      v-if="sidenavToggle"
    >
      <a
        class="nav-item nav-link px-0 mr-lg-4"
        href="javascript:void(0)"
        @click="toggleSidenav"
      >
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse is-nav id="app-layout-navbar">
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2" />

      <profile-Dropdown />
    </b-collapse>
  </b-navbar>
</template>

<script>
import profileDropdown from "@/pages/Users/components/ProfileDropdown.vue";

export default {
  name: "app-layout-navbar",
  components: {
    profileDropdown
  },
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },

    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    }
  }
};
</script>
