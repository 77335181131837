<template>
  <nav class="layout-footer footer" :class="getLayoutFooterBg()">
    <div class="container-fluid container-p-x pb-3">
      <div class="pt-3">
        <span class="footer-text">Skittles Vending</span> © 2023
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "app-layout-footer",

  methods: {
    getLayoutFooterBg() {
      return `bg-${this.layoutFooterBg}`;
    }
  }
};
</script>
