export default function calculateBinLevelRange(level) {
    level = level * 100;
    if(level === 0) {
      return "0%";
    } else if (level <= 25 ) {
      return "1-25%";
    } else if (level > 25 && level <= 50) {
      return "26-50%";
    } else if (level > 50 && level <= 75) {
      return "51-75%";
    } else if (level > 75) {
      return "76-100%";
    }

    return "";
  }