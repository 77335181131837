// Additional polyfills
import "custom-event-polyfill";
import "url-polyfill";

import hasValue from "@/lib/hasValue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Popper from "popper.js";
import Vue from "vue";
import App from "./App";
import useAuth from "./auth/useAuth";
import registerFilters from "./filters/registerFilters";
import globals from "./globals";
import useAppInsights from "./plugins/useAppInsights";
import router from "./router";
import store from "./store";

import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;
Vue.config.productionTip = false;

Vue.config.errorHandler = async (error, instance) => {
  Vue.prototype.$appInsights.trackException({ exception: error });
  window.location.href = "/error?error=" + error;
};

window.onerror = function (message, source, lineno, colno, error) {
  Vue.prototype.$appInsights.trackException({ exception: error });
  window.location.href = "/error?error=" + message;
};

window.onunhandledrejection = function (event) {
  Vue.prototype.$appInsights.trackException({ exception: event });
  window.location.href = "/error?error=" + event.reason;
};

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

useAppInsights();
registerFilters();
useAuth();

// Global RTL flag
Vue.mixin({
  data: function () {
    return {
      ...globals(),
      filedValidationStatus: {}
    };
  },

  methods: {
    requiredField(key, filed) {
      this.$set(this.filedValidationStatus, key, hasValue(filed));
      return this.filedValidationStatus[key];
    },
    validateAttract(key, attractId, noAttractOption) {
      const isValid = !!(attractId || noAttractOption);
      this.$set(this.filedValidationStatus, key, isValid);
      return isValid
    },
    mustSameWith(key, filed1, filed2) {
      this.$set(this.filedValidationStatus, key, filed1 === filed2);
      return this.filedValidationStatus[key];
    },
    DisplaySaveSuccess() {
      this.$bvModal.msgBoxOk("Operation is successful");
    }
  },
  computed: {
    isModelValid() {
      return !this.hasModelError;
    },
    hasModelError() {
      return Object.values(this.filedValidationStatus).some(f => !f);
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
