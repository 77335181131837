<template>
  <div class="error-container">
    <div class="text-center">
      <h2 class="error-status">We're sorry - something went wrong.</h2>
      <div class="error-message"><b>{{ errorMessage }}</b></div>
      <div class="error-message">Contact support at <a href="mailto:vending@d-p.com.au">vending@d-p.com.au</a> if the problem continues.
      </div>
    </div>
  </div>
</template>
  
<style lang="css" scoped>
.error-container {
  margin-top: 10%;
  width: 100%;
  min-height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.error-status {
  margin-top: 5%;
  font-size: 2rem;
  font-weight: 50;
  line-height: 1;
}

.error-message {
  margin-top: 5%;
  font-weight: 100;
  font-size: 1.25rem;
  line-height: 1;
}
</style>
  
<script>

let errorData = "";

try {
  let uri = window.location.href.split('?');
  if (uri.length == 2) {
    let vars = uri[1].split('&');
    if (vars.length > 0) {
      errorData = vars[0];
      let indexOf = errorData.indexOf("=");
      if (indexOf > -1) {
        errorData = decodeURI(errorData.substring(indexOf + 1));
        errorData = errorData.replace("%3A", ":");
      }
    }
  } 
} catch (error) {
  console.log(error);
}

export default {
  name: "error-page",
  metaInfo: {
    title: "Error"
  },
  data() {
    return {
      errorMessage: errorData,
    }
  }
};
</script>
  