import authGuard from "@/auth/authGuard";
import globals from "@/globals";
import Layout2 from "@/layout/Layout2";
import ErrorPage from "@/pages/ErrorPage";
import NotFound from "@/pages/NotFound";
import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  base: "/",
  mode: "history",
  routes: [
    {
      name: "emailVerified",
      path: "/emailVerified",
      component: () => import("@/pages/Users/Verified"),
      props: true,
      meta: { noAuthRequired: true }
    },
    {
      path: "/",
      component: Layout2,
      children: [
        {
          path: "",
          component: () => import("@/pages/Overview/Overview")
        },
        {
          path: "trends",
          component: () => import("@/pages/Trends/Trends")
        },
        {
          path: "sales",
          component: () => import("@/pages/Sales/Sales")
        },
        {
          path: "machines",
          component: () => import("@/pages/Machines/Machines")
        },
        {
          path: "machines/archived",
          component: () => import("@/pages/Machines/Archived")
        },
        {
          path: "machines/add",
          component: () => import("@/pages/Machines/Add")
        },
        {
          name: "machine",
          path: "machines/:id",
          props: true,
          component: () => import("@/pages/Machines/Machine"),
          children: [
            {
              name: "machineDetails",
              path: "",
              component: () => import("@/pages/Machines/MachineDetails"),
              props: true
            },
            {
              name: "machineInteractions",
              path: "interactions",
              component: () => import("@/pages/Machines/Interactions"),
              props: true
            },
            {
              name: "machineEvents",
              path: "events",
              component: () => import("@/pages/Machines/Events"),
              props: true
            },
            {
              name: "machineDevice",
              path: "device",
              component: () => import("@/pages/Machines/MachineDevice"),
              props: true
            },
            {
              name: "machineInventory",
              path: "inventory",
              component: () => import("@/pages/Machines/Inventory"),
              props: true
            },
            {
              name: "machineJournal",
              path: "journal",
              component: () => import("@/pages/Machines/Journal"),
              props: true
            },
            {
              name: "machineStatus",
              path: "status",
              component: () => import("@/pages/Machines/MachineStatus"),
              props: true
            },
            {
              name: "machineLogs",
              path: "logs",
              component: () => import("@/pages/Machines/Logs"),
              props: true
            },
            {
              name: "machineAccess",
              path: "access",
              component: () => import("@/pages/Machines/MachineAccess"),
              props: true
            }
          ]
        },
        {
          path: "devices",
          component: () => import("@/pages/Devices/Devices")
        },
        {
          path: "groups",
          component: () => import("@/pages/Groups/Groups")
        },
        {
          path: "groups/add",
          component: () => import("@/pages/Groups/Add")
        },
        {
          name: "editGroup",
          path: "groups/:id/edit",
          component: () => import("@/pages/Groups/Edit"),
          props: true
        },
        {
          path: "products",
          component: () => import("@/pages/Products/Products")
        },
        {
          path: "products/add",
          component: () => import("@/pages/Products/Add")
        },
        {
          name: "editProduct",
          path: "products/:id/edit",
          component: () => import("@/pages/Products/Edit"),
          props: true
        },
        {
          path: "planograms",
          component: () => import("@/pages/Planograms/Planograms")
        },
        {
          path: "planograms/add",
          component: () => import("@/pages/Planograms/Add")
        },
        {
          name: "editPlanogram",
          path: "planograms/:id/edit",
          component: () => import("@/pages/Planograms/Edit"),
          props: true
        },
        {
          path: "priceLists",
          component: () => import("@/pages/PriceLists/PriceLists")
        },
        {
          path: "priceLists/add",
          component: () => import("@/pages/PriceLists/Add")
        },
        {
          name: "editPriceList",
          path: "priceLists/:id/edit",
          component: () => import("@/pages/PriceLists/Edit"),
          props: true
        },
        {
          path: "attracts",
          component: () => import("@/pages/Attracts/Attracts")
        },
        {
          path: "attracts/add",
          component: () => import("@/pages/Attracts/Add")
        },
        {
          name: "editAttract",
          path: "attracts/:id/edit",
          component: () => import("@/pages/Attracts/Edit"),
          props: true
        },
        {
          path: "status",
          component: () => import("@/pages/Status/Status")
        },
        {
          path: "users",
          component: () => import("@/pages/Users/Users")
        },
        {
          name: "addUser",
          path: "users/add",
          component: () => import("@/pages/Users/Add")
        },
        {
          name: "user",
          path: "users/:id",
          props: true,
          component: () => import("@/pages/Users/User"),
          children: [
            {
              name: "userInfo",
              path: "",
              component: () => import("@/pages/Users/UserInfo"),
              props: true
            },
            {
              name: "permissions",
              path: "permissions",
              component: () => import("@/pages/Users/Permissions"),
              props: true
            },
            {
              name: "rfidTag",
              path: "rfidTag",
              component: () => import("@/pages/Users/RfidTag"),
              props: true
            },
            {
              name: "notifications",
              path: "notifications",
              component: () => import("@/pages/Users/Notifications"),
              props: true
            },
            {
              name: "password",
              path: "password",
              component: () => import("@/pages/Users/Password"),
              props: true
            }
          ]
        },
        {
          name: "transactionsReport",
          path: "reports/transactions",
          component: () => import("@/pages/Reports/Transactions")
        },
        {
          name: "deviceTransactionsReport",
          path: "reports/devicetransactions",
          component: () => import("@/pages/Reports/DeviceTransactions")
        },
        {
          name: "serviceForecastReport",
          path: "reports/serviceforecast",
          component: () => import("@/pages/Reports/ServiceForecast")
        },
        {
          name: "bulkUpdatesReport",
          path: "reports/bulkupdates",
          component: () => import("@/pages/Reports/BulkUpdates")
        },
        {
          name: "eventsReport",
          path: "reports/events",
          component: () => import("@/pages/Reports/Events")
        },
        {
          name: "cashUpdateReport",
          path: "reports/cashupdates",
          component: () => import("@/pages/Reports/CashUpdate")
        },
        {
          name: "deviceInteractionsReport",
          path: "/reports/deviceinteractions",
          component: () => import("@/pages/Reports/DeviceInteractions")
        },
        {
          name: "weeklySales",
          path: "reports/weeklysales",
          component: () => import("@/pages/Reports/WeeklySales")
        },
        {
          name: "RfidTags",
          path: "reports/Rfidtags",
          component: () => import("@/pages/Reports/RfidTags")
        },
        {
          // Error Encountered Page
          path: "/error",
          component: ErrorPage
        }
      ]
    },
    {
      // 404 Not Found page
      path: "*",
      component: NotFound
    }
  ]
});

router.afterEach(() => {
  // Remove initial splash screen
  const splashScreen = document.querySelector(".app-splash-screen");
  if (splashScreen) {
    splashScreen.style.opacity = 0;
    setTimeout(
      () =>
        splashScreen &&
        splashScreen.parentNode &&
        splashScreen.parentNode.removeChild(splashScreen),
      300
    );
  }
  // On small screens collapse sidenav
  if (
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0);
});

router.beforeEach((to, from, next) => {
  document.body.classList.add("app-loading");

  if (to.matched.some(record => record.meta.noAuthRequired)) {
    // No auth required, move to next
    next();
  } else {
    setTimeout(() => {
      authGuard(to, from, next);
    }, 10);
  }
});

router.afterEach((to, from) => {
  Vue.prototype.$appInsights.trackPageView();
});

export default router;
