export default x => {
    function prefix(x) {
        switch (x.state) {
        case "Skittles Bin Enable":
            return "Enabled";
        case "Skittles Bin Disable":
            return "Disabled";
        case null: 
            return "Enabled";
        default:
            return x.state;  
        }
    }
    function suffix(x) {
        switch (x.details) {
            case "Sensor Reading":
                return "";
            case "User Interaction":
                return "(user)";
            default:
                if (x.details.indexOf('Dispensed Count 0 <= Valid')==0){
                    return "(Dispense Count 0)"
                }
                else {
                    return "";
                }
                
        }
    }

    return `${prefix(x)} ${suffix(x)}`;
  }