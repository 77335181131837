import axios from "axios";

import { getInstance } from "../auth/index";

const { baseURL, withCredentials, cors } = window.__env.backendClient;

const config = {
  baseURL,
  headers: {
  },
  // TODO: Configure these per environment
  withCredentials,
  cors
};

const instance = axios.create(config)

instance.interceptors.request.use(
  async config => {
    const auth0 = getInstance();
    const token = await auth0.getTokenSilently();
    config.headers.Authorization = `bearer ${token}`;
    return config;
  }
)

export default instance;
